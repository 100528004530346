import React from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

import Divider from '@material-ui/core/Divider';

import { UserDetails } from '../types';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    '& > *': {
      padding: theme.spacing(2, 0),
    },
  },
  lineContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '65px',
    boxSizing: 'border-box',
  },
  label: {
    fontSize: 16,
    color: theme.palette.secondary.contrastText,
    fontWeight: 700,
  },
}));

interface Props {
  user: UserDetails;
}

const BlockReasonSection = ({ user }: Props) => {
  const { blockReason, blockedAt } = user;

  const classes = useStyles();

  if (!blockedAt) {
    return null;
  }

  return (
    <Card className={classes.container}>
      <Box className={classes.lineContainer}>
        <Typography className={classes.label}>Block reason</Typography>

        <Typography>{blockReason}</Typography>
      </Box>

      <Box>
        <Divider variant="middle" />
      </Box>

      <Box className={classes.lineContainer}>
        <Typography className={classes.label}>Block date</Typography>

        <Typography>{moment(blockedAt).format('LLL')}</Typography>
      </Box>
    </Card>
  );
};

export default BlockReasonSection;
